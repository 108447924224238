/*
 *
 * viewReducer: updates the view state of the app (there are 6 of them)
 *
 */

// constants
import actions from './actionTypes'
import initialState from './initialState'

let viewReducer = (state = initialState.view, action) => {
  switch (action.type) {
    case actions.CHANGE_VIEW:
      return Object.assign({}, state, {view: action.payload})
    case actions.TURN_ANALYSIS_VIEW_ON:
      return Object.assign({}, state, {showAnalysis: true})
    case actions.TURN_ANALYSIS_VIEW_OFF:
      return Object.assign({}, state, {showAnalysis: false})
  }
  return state
}

export default viewReducer
