/*
 *
 * React component: Loading - display
 *
 *
 * props: NA
 *
 * states: NA
 *
 */

// APIs
import React from 'react'

export default class Loading extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      dots: '.'
    }
    this.dotEdit = this.dotEdit.bind(this)
  }

  componentDidMount () {
    this.timer = setInterval(this.dotEdit, 1000)
  }

  componentWillUnmount () {
    clearInterval(this.timer)
  }

  dotEdit () {
    let x = this.state.dots
    if (x.length > 2) {
      this.setState({dots: '.'})
    } else {
      this.setState({dots: x + '.'})
    }
  }

  render () {
    return (
      <div id='loading-display'>
        <h3>Getting your groups{this.state.dots}</h3>
      </div>
    )
  }
}
