/*
 *
 * formatDate: function needed to display 'Last Updated' section of group display
 *
 */

let formatDate = (secs) => {
  let date = new Date(0)
  date.setUTCSeconds(secs)

  let monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]

  let day = date.getDate()
  let monthIndex = date.getMonth()
  let year = date.getFullYear()
  return monthNames[monthIndex] + ' ' +
    day + (year < new Date().getFullYear() ? ' ' + year : '')
}

export default formatDate
