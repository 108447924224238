/*
 *
 * React component: Page
 *
 *
 * props: data (all of state from App),
 *
 * states: NA
 *
 */

// APIs
import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';


// React objects (all displays)
import Loading from './displays/loading'
import Welcome from './displays/welcome'
import LoggedIn from './displays/loggedIn'
import Settings from './displays/settings'
import Share from './displays/share'
import Analysis from './displays/analysis'
import Terms from './displays/terms'
import Privacy from './displays/privacy'
import Team from './displays/team'
// import Groups from './displays/groups'



class Page extends React.Component {
  render () {
    let display = this.props.view

    // we need to do this nested if becuase there are 3 different home views
    // 1st we check if user is logged in, next if analysis is shown
    if (display === 'home') {
      if (!this.props.loggedIn) {
        display = 'welcome'
      } else if (this.props.showAnalysis) {
        display = 'analysis'
      } else {
        display = 'home'
      }
    };

    switch (display) {
      case 'welcome': this.display = <Welcome />; break
      case 'loading': this.display = <Loading />; break
      case 'share': this.display = <Share />; break
      case 'home': this.display = <LoggedIn />; break
      case 'analysis': this.display = <Analysis />; break
      case 'settings': this.display = <Settings />; break
      case 'team': this.display = <Team />; break
      default: this.display = <div>Shit. Something went wrong.</div>
    }; // ^ (: ^

    return (
      <div id='page'>
        <Router>
          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route path="/groups" element={<LoggedIn />} />
            <Route path="/story" element={<Analysis />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Router>        
      </div>
    )
  };
};

let mSTP = state => Object.assign({}, {
  loggedIn: state.login.loggedIn,
  view: state.view.view,
  showAnalysis: state.view.showAnalysis
})

export default connect(mSTP)(Page)
