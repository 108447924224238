import React from 'react';
import { connect } from 'react-redux';

import { useNavigate } from 'react-router-dom';

function withNavigation(Component) {
  return function(props) {
    const navigate = useNavigate();
    return <Component {...props} navigate={navigate} />;
  }
}


class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.handleClickTerms = this.handleClickTerms.bind(this);
        this.handleClickPrivacy = this.handleClickPrivacy.bind(this);
        this.handleClickAbout = this.handleClickAbout.bind(this);
        this.handleClickTeam = this.handleClickTeam.bind(this);
    }

    handleClickTerms() {
        this.props.navigate('/terms');
    }

    handleClickPrivacy() {
        this.props.navigate('/privacy');
    }

    handleClickAbout() {
        this.props.navigate('/');
    }

    handleClickTeam() {
        this.props.navigate('/team');
    }

    render() {
        let divStyle = {
            height: '200px',
        }
        
        return (
            <div style={divStyle}>
                <footer id='footer'>
                    <div className="footer-container">
                        <div className="footer-section">
                            <h3>Project</h3>
                            <ul>
                                <li><a href="#" onClick={this.handleClickAbout}>Home</a></li>
                                {/* <li><a href="#" onClick={this.handleClickTeam}>Our Team</a></li> */}
                                <li><a href="mailto:ksutyla7@gmail.com">Contact</a></li>
                            </ul>
                        </div>
                        <div className="footer-section">
                            <h3>Legal</h3>
                            <ul>
                                {/* <li><a href="#" onClick={this.handleClickTerms}>Terms of Service</a></li> */}
                                <li><a href="#" onClick={this.handleClickPrivacy}>Privacy</a></li>
                            </ul>
                        </div>
                        <div className="footer-section">
                            <h3>Share</h3>
                            <ul>
                                <li><a href="https://twitter.com/intent/tweet?text=Use%20GroupMe?%20Produce%20detailed%20analytics%20for%20your%20group%20at%20https://groupmeanalytics.com/" target="_blank" rel="noopener noreferrer">Twitter</a></li>
                                <li><a href="https://www.reddit.com/submit?url=www.groupmeanalytics.com" target="_blank" rel="noopener noreferrer">Reddit</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <p>&copy; {new Date().getFullYear()} GroupMe Analytics. All rights reserved.</p>
                    </div>
                </footer>
            </div>
        );
    }
}

let mSTP = state => Object.assign({}, {
    view: state.view.view,
    showAnalysis: state.view.showAnalysis
});

// Wrap Footer with navigation capabilities
export default withNavigation(connect(mSTP)(Footer));
