/*
 *
 * initialState: change everything here to update the initialstate of the app (for Redux)
 *
 */

// constants
import initialSettings from '../utils/initialSettings'

const initialState = {
  login: {
    loggedIn: false
  },
  token: {
    userToken: undefined
  },
  view: {
    view: 'home',
    showAnalysis: false
  },
  user: {
    userId: undefined, // still cant figure out how to find these
    userName: undefined, // still cant figure out how to find these
    userGroups: []
  },
  queries: [],
  settings: initialSettings
}

export default initialState
