/*
 *
 * React object: ReturnToGroupSelect - just a button that does the same thing as set view to home
 *
 */

// APIs
import React from 'react'
import { connect } from 'react-redux'

class ReturnToGroupSelectButton extends React.Component {
  constructor (props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  };

  handleClick () { this.props.dispatch({type: 'TURN_ANALYSIS_VIEW_OFF'}) };

  render () {
    return (
        <button onClick={this.handleClick}>See more analytics for this group</button>
    )
  };
};

export default connect()(ReturnToGroupSelectButton)
