/*
 *
 * React component: App
 *
 *
 * props: NA
 *
 *
 */

// APIs
import React from 'react'
import { connect } from 'react-redux'

// React objects
import Page from './app/page'

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      width: window.innerWidth,
    }
  };

  // for window resize
  componentWillMount() { window.addEventListener('resize', this.updateWidth) }
  componentWillUnmount() { window.removeEventListener('resize', this.updateWidth) }

  render() {
    return (
      <div id='browser-display'>
        <Page
          data={this.state}
          updateSettings={this.updateSettings}
          onGo={this.go}
        />
      </div>
    )
  }
}

let mSTP = state => {
  return Object.assign({}, {
    loggedIn: state.login.loggedIn,
    userToken: state.token.userToken,
    view: state.view.view,
    showAnalysis: state.view.showAnalysis
  })
}

export default connect(mSTP)(App)
