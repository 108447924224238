/*
 *
 * React component: Share - display
 *
 *
 * props: NA
 *
 * states: NA
 *
 */

// APIs
import React from 'react'

// React objects
import Title from './elements/title';
import Footer from './elements/footer';

export default class Team extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        let divStyle = {
            textAlign: 'center',
            margin: '100px 10px',
        }

        return (
            <div id='terms-display'>
                <Title />
                <div style={divStyle}>
                    It's basically this one guy named Kevin.
                </div>
                <Footer />
            </div>
        )
    }
}
