/*
 *
 * formatNumber: formats a number to have commas as the thousands seperators
 *
 */

let formatNumber = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export default formatNumber
