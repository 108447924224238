/*
 *
 * loginReducer: handles the login state of the app
 *
 */

// constants
import actions from './actionTypes'
import initialState from './initialState'

let loginReducer = (state = initialState.login, action) => {
  switch (action.type) {
    case actions.LOGIN:
      return Object.assign({}, state, {loggedIn: true})
    case actions.LOGOUT:
      return Object.assign({}, state, {loggedIn: false})
  }
  return state
}

export default loginReducer
