/*
 *
 * initialSettings: set these initial settings for the analysis
 *
 */

let initialSettings = {
  includeLikedMessages: false,
  includeKickedMembers: false,
  includeSelfLikes: true,
  includeSystem: false
}

export default initialSettings
