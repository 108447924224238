/*
 *
 * React component: Share - display
 *
 *
 * props: NA
 *
 * states: NA
 *
 */

// APIs
import React from 'react'

// React objects
import Title from './elements/title';
import Footer from './elements/footer';

export default class Terms extends React.Component {
  constructor (props) {
    super(props)
  }

  render () {
    return (
      <div id='terms-display'>
        <Title />
        <div id="terms-display-content">
          <h2>Terms of Service</h2>
          <h3>1. Introduction</h3>
          <p>Welcome to the GroupMe Analytics Project! These terms and conditions outline the rules and regulations for the use of our Website, located at groupmeanalytics.com.</p>
          <p>By accessing this website, we assume you accept these terms and conditions. Do not continue to use groupmeanalytics.com if you do not agree to take all of the terms and conditions stated on this page.</p>
          <h3>2. Intellectual Property Rights</h3>
          <p>Other than the content you own, under these terms, GroupMe Analytics and/or its licensors own all the intellectual property rights and materials contained in this Website.</p>
          <p>You are granted limited license only for purposes of viewing the material contained on this Website.</p>
          <h3>3. Restrictions</h3>
          <p>You are specifically restricted from all of the following: Publishing any Website material in any other media; Selling, sublicensing, and/or otherwise commercializing any Website material; Publicly performing and/or showing any Website material without permission; Using this Website in any way that is or may be damaging to this Website; Using this Website in any way that impacts user access to this Website; Using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity; Engaging in any data mining, data harvesting, data extracting, or any other similar activity in relation to this Website; Using this Website to engage in any advertising or marketing.</p>
          <h3>4. Limitation of Liability</h3>
          <p>In no event shall GroupMe Analytics, nor any of its officers, directors, and employees, shall be held liable for anything arising out of or in any way connected with your use of this Website whether such liability is under contract. [Your Company], including its officers, directors, and employees shall not be held liable for any indirect, consequential, or special liability arising out of or in any way related to your use of this Website.</p>
          <h3>5. Indemnification</h3>
          <p>You hereby indemnify to the fullest extent GroupMe Analytics from and against any and/or all liabilities, costs, demands, causes of action, damages, and expenses arising in any way related to your breach of any of the provisions of these Terms.</p>
          <h3>6. Severability</h3>
          <p>If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.</p>
          <p>These Terms of Service have been generated with the help of the Terms Of Service Generator and the Privacy Policy Generator.</p>
        </div>
        <Footer />
      </div>
    )
  }
}
