/*
 *
 * React component: Share - display
 *
 *
 * props: NA
 *
 * states: NA
 *
 */

// APIs
import React from 'react'

// React objects
import Title from './elements/title'
import content from './content'

export default class Share extends React.Component {
  constructor (props) {
    super(props)
    this.twitterClick = this.twitterClick.bind(this)
    this.redditClick = this.redditClick.bind(this)
  }

  // function that get activated on click
  twitterClick () { window.location.href = 'https://twitter.com/intent/tweet?text=Use%20GroupMe?%20Produce%20detailed%20analytics%20for%20your%20group%20at%20https://groupmeanalytics.com/' }
  redditClick () { window.location.href = 'https://www.reddit.com/submit?url=www.groupmeanalytics.com' }

  render () {
    return (
      <div id='share-display'>
        <Title />
        <div id='share-display-content'>
          <h3>Share</h3>
          <div className='share-buttons'>
            <button id='twitter-button' onClick={this.twitterClick}>
              <i className='fa fa-twitter-square' />
            </button>
            <button id='reddit-button' onClick={this.redditClick}>
              <i className='fa fa-reddit-square' />
            </button>
          </div>
        </div>
      </div>
    )
  }
}
