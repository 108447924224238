/*
 *
 * React component: Settings - display
 *
 *
 * props: the current settings and the method when 'apply' is clicked (lets keep these out of redux)
 *
 * states: the settings
 *
 */

// APIs
import React from 'react'
import { connect } from 'react-redux'

// React objects
import Title from './elements/title'

// constants
import applySettingsChange from '../../actions/applySettingsChange'

class Settings extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      settings: this.props.settings
    }
    this.apply = this.apply.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  apply () {
    this.props.dispatch(applySettingsChange(this.state.settings))
  }

  handleChange (e) {
    let x = this.state.settings
    x[e.target.id] = e.target.checked
    this.setState({settings: x})
  }

  render () {
    return (
      <div id='settings-display'>
        <Title />
        <h3>Analysis Settings</h3>
        <div id='settings-table'>
          <table>
            <tbody>
              <tr>
                <td id='settings-query'>Include unliked messages in likes per message calculation?</td>
                <td id='settings-toggle'>
                  <label id='switch'>
                    <input
                      type='checkbox'
                      id='includeLikedMessages'
                      defaultChecked={this.state.settings.includeLikedMessages}
                      onChange={this.handleChange}
                    />
                    <span id='slider' />
                  </label>
                </td>
              </tr>
              <tr>
                <td id='settings-query'>Analyze messages from members who have since been kicked?</td>
                <td id='settings-toggle'>
                  <label id='switch'>
                    <input
                      type='checkbox'
                      id='includeKickedMembers'
                      defaultChecked={this.state.settings.includeKickedMembers}
                      onChange={this.handleChange}
                    />
                    <span id='slider' />
                  </label>
                </td>
              </tr>
              <tr>
                <td id='settings-query'>Include self-likes in a members total likes count?</td>
                <td id='settings-toggle'>
                  <label id='switch'>
                    <input
                      type='checkbox'
                      id='includeSelfLikes'
                      defaultChecked={this.state.settings.includeSelfLikes}
                      onChange={this.handleChange}
                    />
                    <span id='slider' />
                  </label>
                </td>
              </tr>
              <tr>
                <td id='settings-query'>Include system* messages in the analysis?</td>
                <td id='settings-toggle'>
                  <label id='switch'>
                    <input
                      type='checkbox'
                      id='includeSystem'
                      defaultChecked={this.state.settings.includeSystem}
                      onChange={this.handleChange}
                    />
                    <span id='slider' />
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
          <hr align='center' width='80%' />
        </div>
        <div id='settings-notes'>
          <p>*System messages are "messages" sent by the groupMe system (ie. when someone is kicked from or added to the group).</p>
        </div>
        <div id='settings-submit'>
          <button id='apply-settings' onClick={this.apply}>Apply</button>
        </div>
      </div>
    )
  }
}

let mSTP = state => Object.assign({}, {
  settings: Object.assign({}, state.settings)
})

export default connect(mSTP)(Settings)
