import React from 'react'

export default class SelectAGroup extends React.Component {
  render() {
    return (
      <div id='select-a-group-container'>
        <div id='select-a-group'>
          <p>Below are all of the GroupMe groups that you are a member of.</p>
        </div>
        <div id='select-a-group'>
          <p>Select one for us to write a story about.</p>
        </div>
      </div>
    )
  }
}
