/*
 *
 * tokenReducer: handles the userToken state of the app (so we can keep making data fetches)
 *
 */

// constants
import actions from './actionTypes'
import initialState from './initialState'

let tokenReducer = (state = initialState.token, action) => {
  switch (action.type) {
    case actions.SET_USER_TOKEN:
      return Object.assign({}, state, {userToken: action.payload})
  }
  return state
}

export default tokenReducer
