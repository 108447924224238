// APIs
import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Higher-order component to provide navigation
function withNavigation(Component) {
  return function(props) {
    const navigate = useNavigate();
    return <Component {...props} navigate={navigate} />;
  };
}

class ReturnToGroupSelectButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    // Navigate to the /groups route
    this.props.navigate('/groups');
  }

  render() {
    return (
      <button onClick={this.handleClick}>Select a different group</button>
    );
  }
}

export default connect()(withNavigation(ReturnToGroupSelectButton));
