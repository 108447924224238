/*
 *
 * React component: Analysis - display
 *
 */

// APIs
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// React objects
import Title from './elements/title';
import Footer from './elements/footer';
import GroupAnalysis from './elements/groupAnalysis';
import Waiting from './elements/waiting';
import Story from './elements/story';
import ReturnToGroupSelectButton from './elements/returnToGroupSelectButton';
import GenerateAgainButton from './elements/generateAgainButton';
import ShareStoryButton from './elements/shareStoryButton';
import SeeMoreAnalysisButton from './elements/seeMoreAnalysisButton';

// constants
import analysis from '../../analysis';
import inHouseAnalysis from '../../utils/inHouseAnalysis';

function withNavigation(Component) {
  return function(props) {
    const navigate = useNavigate();

    useEffect(() => {
      // Check for the necessary Redux states
      if (
        !props.userToken ||
        !props.latestQuery ||
        !props.queries ||
        props.queries.length === 0 ||
        !props.responseData
      ) {
        navigate('/'); // Redirect to '/' if any required state is missing
      }
    }, [props.userToken, props.latestQuery, props.queries, props.responseData, navigate]);

    return <Component {...props} navigate={navigate} />;
  };
}

class Analysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      storyText: null,
      windowWidth: window.innerWidth,
    };
    this.handleError = this.handleError.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  handleError(e) {
    console.log('There was an error :(');
    this.props.dispatch({ type: 'TURN_ANALYSIS_VIEW_OFF' });
  }

  componentDidMount() {
    // Safeguard against missing latestQuery
    if (!this.props.latestQuery || !this.props.latestQuery.query) {
      this.props.navigate('/');
      return;
    }

    window.addEventListener('resize', this.handleResize);

    // Check if the current query has been made before (searching in reverse order)
    let duplicateQuery = null;
    for (let i = this.props.queries.length - 1; i >= 0; i--) {
      if (this.props.queries[i].query.groupId === this.props.latestQuery.query.groupId) {
        if (this.props.queries[i].response && Object.keys(this.props.queries[i].response).length > 0) {
          duplicateQuery = this.props.queries[i];
          break; // Exit the loop as soon as the latest duplicate is found
        }
      }
    }

    // Now update the response data
    if (duplicateQuery) {
      this.setState({ storyText: duplicateQuery.response });
      this.props.dispatch({ type: 'UPDATE_RESPONSE_DATA', payload: duplicateQuery.response });
    } else {
      inHouseAnalysis(this.props.latestQuery.query).then(resp =>
        analysis(resp).then(resp => {
          this.setState({ storyText: resp });
          this.props.dispatch({ type: 'UPDATE_RESPONSE_DATA', payload: resp });
        })
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({ windowWidth: window.innerWidth });
  }

  render() {
    // Safeguard against missing latestQuery
    if (!this.props.latestQuery || !this.props.latestQuery.response) {
      return null; // or some fallback UI
    }

    let mainStyle = {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '470px',
      width: 'calc(100% - 20px)',
      marginBottom: '0px',
      marginTop: '15px',
      textAlign: 'justify',
      padding: '0',
      lineHeight: '1.25',
      whiteSpace: 'pre-line',
    };

    let buttonContainerStyles = {
      textAlign: 'center',
      marginTop: '50px',
      marginBottom: '50px',
    };

    if (Object.keys(this.props.latestQuery.response).length > 0) {
      return (
        <div id="analysis-display">
          <Title />
          <GroupAnalysis groupName={this.props.latestQuery.query.groupName} />
          <div id="analysis-display-main" style={mainStyle}>
            <Story text={this.props.latestQuery.response} />
          </div>
          <div id="return-to-group-select-button" style={buttonContainerStyles}>
            <ShareStoryButton />
            <br />
            <GenerateAgainButton />
            <br />
            <ReturnToGroupSelectButton />
          </div>
          <Footer />
        </div>
      );
    } else {
      return (
        <div id="analysis-display">
          <Title />
          <br />
          <div id="analysis-display-main" style={mainStyle}>
            <Waiting />
          </div>
          <Footer />
        </div>
      );
    }
  }
}

let mSTP = (state) =>
  Object.assign({}, {
    latestQuery: state.queries && state.queries.length > 0 ? { ...state.queries[state.queries.length - 1] } : null,
    queries: state.queries || [],
    responseData: state.response ? { ...state.response } : {},
    userToken: state.token.userToken, // Add userToken to props
  });

export default connect(mSTP)(withNavigation(Analysis));
