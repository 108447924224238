/*
 *
 * queriesReducer: handles the queries the user makes to the groupMe API
 *
 */

// constants
import actions from './actionTypes'
import initialState from './initialState'

let queriesReducer = (state = initialState.queries, action) => {
  switch (action.type) {
    case actions.ADD_QUERY:
      // calculate the id to be either 0 or the # of queryIds already in the state
      let id = (state.length === 0) ? 0 : state.map(d => d.queryId)[state.length - 1] + 1
      return [...state, {queryId: id, query: action.payload, response: {}}]
    case actions.UPDATE_RESPONSE_DATA:
      // update the latest state with the response
      let newObject = Object.assign({}, state[state.length - 1]) // the last query
      newObject.response = action.payload
      // replace the last state with the updated state
      let newState = [...state]
      newState.pop()
      newState.push(newObject)
      return newState
  }
  return state
}

export default queriesReducer
