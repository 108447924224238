/*
 *
 * settingsReducer: handles the settings the user updates on apply
 *
 */

// constants
import actions from './actionTypes'
import initialState from './initialState'

let settingsReducer = (state = initialState.settings, action) => {
  switch (action.type) {
    case actions.UPDATE_SETTINGS:
      return Object.assign({}, Object.assign({}, action.payload))
  }
  return state
}

export default settingsReducer
