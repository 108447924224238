
// APIs
import React from 'react'
import { connect } from 'react-redux'

class Title extends React.Component {
  constructor (props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick () {
    let view = this.props.view;
    (view === 'home' && this.props.showAnalysis) && this.props.dispatch({type: 'TURN_ANALYSIS_VIEW_OFF'});
    (view !== 'home') && this.props.dispatch({type: 'CHANGE_VIEW', payload: 'home'})
  }

  render () {
    return (
      <div id='page-title' onClick={this.handleClick}>
        <br />
        <h1>GroupMe Short Stories Project</h1>
        <img src="../../images/scroll.png" width='50px' height='50px' alt="scroll" />
        <img src='../../images/logo.svg' width='50px' height='50px' />
        <img src="../../images/scroll.png" width='50px' height='50px' alt="scroll" />
      </div>
    )
  }
}

let mSTP = state => Object.assign({}, {
  view: state.view.view,
  showAnalysis: state.view.showAnalysis
})

export default connect(mSTP)(Title)
