import React from 'react'

export default class Waiting extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      dots: '.'
    }
    this.dotEdit = this.dotEdit.bind(this)
  }

  componentDidMount () {
    this.timer = setInterval(this.dotEdit, 1000)
  }

  componentWillUnmount () {
    clearInterval(this.timer)
  }

  dotEdit () {
    let x = this.state.dots
    if (x.length > 2) {
      this.setState({dots: '.'})
    } else {
      this.setState({dots: x + '.'})
    }
  }

  render () {
    this.style = {
      paddingTop: '50px',
      textAlign: 'center',
      width: '100%',
    }

    return (
      <div id='waiting-container' style={this.style} >
        <div id='waiting'>
          <h3>Writing your group's story.</h3>
          <h3>This may take a few seconds.</h3>
          <h3>{this.state.dots}</h3>
          <br />
          <br />
          <br />
          <img fill='black' src='images/loader.svg' />
        </div>
      </div>
    )
  }
}