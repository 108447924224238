/*
 *
 * React object: ReturnToGroupSelect - just a button that does the same thing as set view to home
 *
 */

// APIs
import React from 'react';
import { connect } from 'react-redux';

class ReturnToGroupSelectButton extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        const titleElement = document.getElementById('story-title');
        const element = document.getElementById('analysis-display-main');
        const title = titleElement ? titleElement.innerText : '';
        const textToShare = element.innerText || element.textContent;

        const combinedText = `${title}\n\n${textToShare}\n\n${window.location.href}`;

        // Copy the combined text to the clipboard
        navigator.clipboard.writeText(combinedText).then(() => {
            alert('The story has been copied to your clipboard. You can now paste it into a message or email.');
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    }

    render() {
        return (
            <button onClick={this.handleClick}>Share this story!</button>
        );
    }
}

export default connect()(ReturnToGroupSelectButton);
