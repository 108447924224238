/*
 *
 * Generate Another Story Button
 *
 */

// APIs
import React from 'react';
import { connect } from 'react-redux';
import inHouseAnalysis from '../../../utils/inHouseAnalysis';
import analysis from '../../../analysis';

class ReturnToGroupSelectButton extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        // add the query
        const newQuery = this.props.latestQuery.query;
        this.props.dispatch({ type: 'ADD_QUERY', payload: newQuery });

        // Perform inHouseAnalysis to generate a new story
        inHouseAnalysis(newQuery).then(resp =>
            analysis(resp).then(newStory => {
                // Replace the response with the new story
                this.props.dispatch({ type: 'UPDATE_RESPONSE_DATA', payload: newStory });

            })
        );
    }

    render() {
        return (
            <button onClick={this.handleClick}>Generate another story for this group</button>
        );
    }
}

const mapStateToProps = (state) => ({
    latestQuery: state.queries[state.queries.length - 1], // Get the latest query
    data: state.user.userGroups,
});

export default connect(mapStateToProps)(ReturnToGroupSelectButton);
