import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getFirestore } from 'firebase/firestore';
import go from '../../../actions/go';

// Importing formatNumber and formatDate
import formatNumber from '../../../utils/formatNumber'; // Adjust path if necessary
import formatDate from '../../../utils/formatDate'; // Adjust path if necessary

function Group(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {

    const members = props.data.members.map(mem => mem.user_id).join(',');
    const requestData = {
      groupId: props.data.id,
      userToken: props.userToken,
      groupName: props.data.name,
      groupMembers: members,
      ...props.currentAnalysisSettings
    };

    dispatch( {type: 'ADD_QUERY', payload: requestData} );
    navigate('/story');
    // go(requestData, db, navigate);
  };

  let pStyle = {
    margin: '0px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  };

  let titleStyle = {
    width: 'inherit',
    marginTop: '0px',
    marginBottom: '20px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  };

  let groupDisplayTextStyle = {
    width: '100%',
    textAlign: 'left',
    paddingRight: '30px',
  };

  return (
    <div className='group' id='group-display' onClick={handleClick}>
      <img src={props.data.image_url || 'images/title.png'} />
      <div style={groupDisplayTextStyle}>
        <div id='group-display-title'><h4 style={titleStyle}>{props.data.name}</h4></div>
        <div id='quick-stats'>
          <p style={pStyle}>Total members: {props.data.members.length}</p>
          <p style={pStyle}>Total messages: {formatNumber(props.data.messages.count)}</p>
          <p style={pStyle}>Last updated: {formatDate(props.data.updated_at)}</p>
        </div>
      </div>
    </div>
  );
}

let mSTP = state => Object.assign({}, {
  userToken: state.token.userToken,
  currentAnalysisSettings: state.settings,
  queries: state.queries
});

export default connect(mSTP)(Group);
