/*
 *
 * shh!
 *
 */

let secrets = {
  apiGroupUrl: 'https://api.groupme.com/v3/groups?token=',
  apiMessagesUrl: 'https://api.groupme.com/v3/groups/',
  clientId: '4yrpQj7eUQvkmqWSVvqxOLrhZs8YYfD6jR9k0yKHb6b9wFzL',
  kevinsAccessToken: 'no way your getting that',
  url: './analysis?'
}

export default secrets

