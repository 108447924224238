/*
 *
 * applySettingsChange: this will update the settings and view accordingly
 *
 */

function applySettingsChange (newSettings) {
  return [
    {type: 'UPDATE_SETTINGS', payload: newSettings},
    {type: 'TURN_ANALYSIS_VIEW_OFF'},
    {type: 'CHANGE_VIEW', payload: 'home'}
  ]
}

export default applySettingsChange
