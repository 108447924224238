// APIs
import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Higher-order component to provide navigation
function withNavigation(Component) {
  return function(props) {
    const navigate = useNavigate();
    return <Component {...props} navigate={navigate} />;
  };
}

class ReturnToHomeButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    // Navigate to the home page
    this.props.navigate('/');
  }

  render() {
    return (
      <button onClick={this.handleClick}>Return to the homepage</button>
    );
  }
}

export default connect()(withNavigation(ReturnToHomeButton));
