/*
 *
 * React component: Share - display
 *
 *
 * props: NA
 *
 * states: NA
 *
 */

// APIs
import React from 'react'

// React objects
import Title from './elements/title';
import Footer from './elements/footer';

import ReturnToHomeButton from './elements/returnToHomeButton';


export default class Privacy extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        let buttonContainerStyles = {
            textAlign: 'center',
            marginTop: '50px',
            marginBottom: '50px',
        };

        return (
            <div id='terms-display'>
                <Title />
                <div id="terms-display-content">
                    <h2>Privacy Policy</h2>
                    <p>Welcome to the GroupMe Short Stories Project! This Privacy Policy outlines how we collect, use, and protect your personal information when you use groupmeshortstories.com.</p>

                    <p>By using our website, you agree to the collection and use of information in accordance with this Privacy Policy.</p>

                    <h3>1. Information We Collect</h3>
                    <p>We primarily collect your GroupMe messaging data (including but not limited to your groups, group messages, your groups' users) to feed into an AI prompt.</p>

                    <h3>2. Use of Your Information</h3>
                    <p>We use the information we collect for one main purpose: to prompt a general AI (Google Gemini) to output an entertaining short story about one of your GroupMe groups.</p>
                    <p>We do not save group or messaging data. Once you leave the page, none of your output can be recovered.</p>

                    <h3>3. Cookies</h3>
                    <p>We do not use cookies.</p>

                    <h3>5. Security</h3>
                    <p>The security of your personal information is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>

                    <h3>6. Links to Other Sites</h3>
                    <p>Our Service may contain links to other sites that are not operated by us. If you click a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>

                    <h3>7. Changes to This Privacy Policy</h3>
                    <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

                    <h3>8. Contact Us</h3>
                    <p>If you have any questions about our Privacy Policy, please contact us.</p>

                    <h3>9. Consent</h3>
                    <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>


                </div>
                <div id="return-to-group-select-button" style={buttonContainerStyles}>
                    <ReturnToHomeButton />
                </div>
                <Footer />
            </div>
        )
    }
}
