/*
 *
 * React component: Story display
 *
 *
 * props: NA
 *
 * states: NA
 *
 */

// APIs
import React from 'react'

export default class Story extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div>
        {this.props.text}
      </div>
    )
  }
}