/*
 *
 * inHouseAnalysis: 2024 update - we are bringing the message analysis in house (in JS code, not Python)
 *
 */


// constants
import secrets from './secrets'


async function inHouseAnalysis(request) {
    // console.log(request);
    
    // set the url
    let requestUrl = secrets.apiMessagesUrl + request.groupId + '/messages?token=' + request.userToken + '&limit=100';

    // make the request
    let response = await fetch(requestUrl, { method: 'GET', headers: { 'Content-Type': 'application/json' } });
    let data = await response.json();
    let messages = data.response.messages;
    let numOfMessages = data.response.count;
    let last = messages[messages.length - 1].id;

    // now loop to get through the messages
    let maxNumOfMessages = 2000;
    while (messages.length < numOfMessages && messages.length < maxNumOfMessages) {
        // get the next messages by reusing the same variables we had before (response, data)
        let requestUrlTemp = requestUrl + '&before_id=' + last;
        response = await fetch(requestUrlTemp, { method: 'GET', headers: { 'Content-Type': 'application/json' } });
        data = await response.json();

        // add them to the messages array
        for (let i = 0; i < data.response.messages.length; i++) {
            messages.push(data.response.messages[i]);
        }

        // reset the last variable
        last = messages[messages.length - 1].id;
    }

    // now we have the messages. we need to analyze them
    // console.log(messages);
    
    // first set the output variable
    let analysisOutput = {};

    // total messages in the group
    analysisOutput.messageCount = numOfMessages;

    // total people in the group
    analysisOutput.memberCount = request.groupMembers.split(',').length;

    // calculate the stats
    analysisOutput.stats = analyzeMessages(messages);

    // add all of the messages
    analysisOutput.messages = messages.map((x, i) => x.name + ": " + x.text);

    // for troubleshooting
    // console.log(analysisOutput);
    // console.log(analysisOutput.messages);
    // console.log(JSON.stringify(analysisOutput.messages).length);

    // return the output
    return analysisOutput;
}



// function to get the stats
function analyzeMessages(messages) {
    const analysis = {};

    messages.forEach(message => {
        const { sender_id, name, favorited_by } = message;

        // Skip processing if the sender is 'GroupMe'
        if (name === 'GroupMe') {
            return; // Continue to the next iteration of the forEach loop
        }

        // Initialize sender in analysis if not already present
        if (!analysis[sender_id]) {
            analysis[sender_id] = {
                Name: name,
                MessagesSent: 0,
                LikesReceived: 0,
                LikesGiven: 0,
            };
        }

        // Update name to the most recent one used by the sender
        analysis[sender_id].Name = name;

        // Increment message count
        analysis[sender_id].MessagesSent += 1;

        // Increment likes received for this message
        analysis[sender_id].LikesReceived += favorited_by.length;

        // Increment likes given by each user in favorited_by
        favorited_by.forEach(userId => {
            // Exclude the case where the 'GroupMe' user would be incrementing likes given
            if (analysis[userId]) {
                analysis[userId].LikesGiven += 1;
            } else {
                // Initialize the user if they've only liked messages but haven't sent any,
                // except for 'GroupMe'
                if (name !== 'GroupMe') {
                    analysis[userId] = {
                        Name: "", // This will be updated when a message by this user is encountered
                        MessagesSent: 0,
                        LikesReceived: 0,
                        LikesGiven: 1, // Start with 1 since they've liked this message
                    };
                }
            }
        });
    });

    return analysis;
}




export default inHouseAnalysis
