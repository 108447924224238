/*
 *
 * actionTypes: object of all redux actions. Helpful in making sure there
 *   are no repeats
 */

let actions = {
  // to loginReducer
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  // to tokenReducer
  SET_USER_TOKEN: 'SET_USER_TOKEN',
  // to viewReducer
  CHANGE_VIEW: 'CHANGE_VIEW',
  TURN_ANALYSIS_VIEW_ON: 'TURN_ANALYSIS_VIEW_ON', // no payload
  TURN_ANALYSIS_VIEW_OFF: 'TURN_ANALYSIS_VIEW_OFF', // no payload
  // to userReducer
  SET_USER_GROUPS: 'SET_USER_GROUPS',
  // to queriesReducer
  ADD_QUERY: 'ADD_QUERY',
  UPDATE_RESPONSE_DATA: 'UPDATE_RESPONSE_DATA',
  // to settingsReducer
  UPDATE_SETTINGS: 'UPDATE_SETTINGS'
}

export default actions
