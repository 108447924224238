import React from 'react'

export default class SelectAGroup extends React.Component {
  render() {
    let containerStyle = {
      textAlign: 'center',
      position: 'relative',
      marginTop: '25px',
      marginBottom: '0px',
    }

    let pStyle = {
      marginBottom: '0px',
    }

    return (
      <div id='select-a-group-container' >
        <div id='story-title' style={containerStyle}>
        <h3>{this.props.groupName}</h3>
        <p style={pStyle}>a short story..</p>
      </div>
      </div >
    )
  }
}
